import * as React from 'react';
import moment from "moment";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog  from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import UserSelectDialog from '../components/UserSelectDialog';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';

export default function DataGridDemo() {

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'timestamp', headerName: 'timestamp', width: 220,   type: 'dateTime',  valueFormatter: params => moment(params?.value).format("DD.MM.YYYY HH:mm:ss") },
    { field: 'eventType', headerName: 'eventType', width: 190 },
    { field: 'eventSource', headerName: 'eventSource', width: 190 },
    { field: 'metadata', headerName: 'metadata', width: 700, valueGetter: (params) => { return Object.entries(params.row.metadata).map(([key, value]) => { return key+':'+value+';'; }  ) }},
  ];

  const columnsDetail: GridColDef[] = [
    { field: 'id', headerName: 'key', width: 220 },
    { field: 'value', headerName: 'value', width: 400 },
  ];


    // OPEN DIALOG
    const handleOpen = (props:any) => {
      setAddress(props.eventType);
      setconsentizerIdDialog(props.consentizerId);
      
      setTableDataDetail(Object.entries(props.metadata).map(([key, value]) => ({
          id: key,
          value: value
        })
      ));
      setOpen(true);
    };

    //CLOSE DIALOG
    const handleClose = () => {
      setOpen(false);
    };

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [tableDataDetail, setTableDataDetail] = useState([{}]);
    const [consentizerId, setConsentizerId] = useState('');
    const [encConsentizerId, setEncConsentizerId] = useState('');
    const [eventType, setEventType] = useState('');
    const [eventSource, setEventSource] = useState('');


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [address, setAddress] = useState('');
    const addressChangeHandler = useCallback(function _addressChangeHandler(event: any) {
        setAddress(event.target.value);
    }, []);

    const [consentizerIdDialog, setconsentizerIdDialog] = useState('');
    const consentizerIdDialogChangeHandler = useCallback(function _addressChangeHandler(event: any) {
        setconsentizerIdDialog(event.target.value);
    }, []);

    const changeConsentizerId = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setConsentizerId(event.target.value)
    }

    const changeEncConsentizerId = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setEncConsentizerId(event.target.value)
    }

    const changeEventSource = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setEventSource(event.target.value)
    }

    const userSelected = (selectedItem: string) => {
      // Handle the selected item
      if (selectedItem !== '')
        setConsentizerId(selectedItem);
    };



    const buttonHandler = async() => {

        console.log("button clicked"+consentizerId);

        if (consentizerId === '' && encConsentizerId === '' && eventType === '') return;

        setLoading(true);

        const token = await getAccessTokenSilently();

        const response = await fetch(process.env.REACT_APP_EVENTS_URL +"?consentizerId="+consentizerId+"&encConsentizerId="+encConsentizerId+"&eventType="+eventType+"&eventsource="+eventSource, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (response.status) {
            const events = await response.json();
            if (events.events != null)
              setTableData(events.events);
            console.log("data loaded.")
          }
        setLoading(false);
    };


   
  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
        <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <TextField label="ConsentizerId" id="txtConsentizerId" required value={consentizerId} onChange={changeConsentizerId} inputProps={{ style: { width: "300px" }}}
                   />
                <UserSelectDialog
                  onItemSelected={userSelected}
                />
                <TextField label="encConsentizerId" id="txtEncConsentizerId" required value={encConsentizerId} onChange={changeEncConsentizerId} inputProps={{ style: { width: "300px" }}}
                   />

                <TextField label="eventSource" id="eventSource" required value={eventSource} onChange={changeEventSource} inputProps={{ style: { width: "100px" }}}
                   />
                <FormControl sx={{ m: 1, minWidth: 300 }}>
                  <InputLabel id="demo-simple-select-label">Event type</InputLabel>
                  <Select
                    id="demo-simple-select"
                    label="Event type"
                    value={eventType}
                    onChange={(event: SelectChangeEvent) => { setEventType(event.target.value as string); }}
                    style={{ minWidth: "170px",padding:"5px" }}
                  >
                    <MenuItem value={''}>All</MenuItem>
                    <MenuItem value={'userLogin'}>User login</MenuItem>
                    <MenuItem value={'userLogout'}>User logout</MenuItem>
                    <MenuItem value={'anonymGenerated'}>Anonym generated</MenuItem>
                    <MenuItem value={'consentSaved'}>Consent saved</MenuItem>
                    <MenuItem value={'webVisit'}>Web visit</MenuItem>
                    <MenuItem value={'webShow'}>Web show</MenuItem>
                    <MenuItem value={'webConsent'}>Web consent</MenuItem>
                    <MenuItem value={'webMutation'}>Web mutation</MenuItem>
                    <MenuItem value={'addonInstalled'}>Addon installed</MenuItem>
                    <MenuItem value={'addonInitialized'}>Addon initialized</MenuItem>
                    <MenuItem value={'profileCollision'}>Profile collision</MenuItem>
                    <MenuItem value={'controlPanelDisplayed'}>Control panel displayed</MenuItem>
                    <MenuItem value={'webActionsMissing'}>Web action missing</MenuItem>
                    <MenuItem value={'userProfileEdited'}>User profile edited</MenuItem>
                    <MenuItem value={'userPasswordChanged'}>User password changed</MenuItem>
                    <MenuItem value={'cpDisplay'}>CP display</MenuItem>
                    <MenuItem value={'blockedRequest'}>Blocked request</MenuItem>
                    <MenuItem value={'addonFuncSwitching'}>Addon func switching</MenuItem>
                    <MenuItem value={'piiDelete'}>PII delete</MenuItem>
                  </Select>
                </FormControl>
                <Button variant="contained" onClick={buttonHandler}>Search</Button>
            </div>
            <Box sx={{ width: '100%' }}>
                { loading ? 
                  <LinearProgress key='waitingStatus'  />
                : null}
            </Box>
        </Box>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
        rowCount={100}
      />

<Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "720px!important",
          },
        }}
      >
      <DialogTitle>Event</DialogTitle>

        <DialogContent dividers>
            <TextField
                helperText="consentizerId"
                placeholder="consentizerId"
                value={consentizerIdDialog}
                onChange={consentizerIdDialogChangeHandler}
                fullWidth 
            />
            <TextField
                helperText="eventType"
                placeholder="eventType"
                value={address}
                onChange={addressChangeHandler}
            />
                  <DataGrid
                      rows={tableDataDetail}
                      columns={columnsDetail}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                      disableSelectionOnClick
                      hideFooter={true}
                      autoHeight={true}
                      experimentalFeatures={{ newEditingApi: true }}
                  />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        </Dialog>
    </Box>
  );
}